import { routeType } from "../../../Type";
import About from "../../Public/AboutUs/About";
import AwardsPage from "../../Public/Awards/Awards";
import AppointmentBook from "../../Public/Bookappoinment/AppoinmentModal";
import ContactUs from "../../Public/ContactUs/ContactUs";
import Doctors from "../../Public/Doctors/Doctors";
import NewsEvents from "../../Public/News/NewsEvents";
import Services from "../../Public/Services/Services";
import {
  DoctorsContainer,
  HomeIndex,
  PrivacyPolicy,
  TermsAndConditions,
} from "../Imports";

export const publicRoutes: routeType[] = [
  {
    path: "/",
    element: <HomeIndex />,
  },
  {
    path: "/about-us",
    element: <About />,
  },

  {
    path: "/doctors",
    element: <Doctors />,
  },

  {
    path: "/news-and-events",
    element: <NewsEvents />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },

  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/awards",
    element: <AwardsPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/doctor/profile/:id",
    element: <DoctorsContainer />,
  },
  {
    path: "/book/appoinment",
    element: <AppointmentBook />,
  },
];
