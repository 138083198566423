import React, { useRef } from "react";
import {
  Awards,
  Banner,
  Contact, Team,
  Testimonials
} from "../../Routes/Imports";
import Appointments from "../Bookappointments/AppointmentsContainer";


const HomeIndex: React.FC = () => {

  const appointmentsRef = useRef<HTMLDivElement | null>(null);
  const scrollToAppointments = () => {
    if (appointmentsRef.current) {
      appointmentsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="home-page-full-wrap">
      <Banner scrollToAppointments={scrollToAppointments} />
      <Team />
      <div ref={appointmentsRef}>
        <Appointments />
      </div>
      <Awards />
      {/* <News /> */}
      <Testimonials />
      <Contact />
      </div>
    </>
  );
};

export default HomeIndex;
