import React from 'react';
import PrivacyPolicyBanner from './Containers/PrivacyPolicyBanner';
import PrivacyPolicyList from './Containers/PrivacyPolicyList';

const PrivacyPolicy:React.FC = () => {
	return (
		<>
			<PrivacyPolicyBanner />
			<PrivacyPolicyList />
		</>
	);
};

export default PrivacyPolicy;