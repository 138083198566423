import React from 'react'
import {ContactUsBanner,ContactUsForm} from '../../Routes/Imports'

const ContactUs:React.FC = () => {
  return (
    <>
    <ContactUsBanner/>
    <ContactUsForm/>
    </>
  )
}

export default ContactUs
