import React from 'react';
import {ServicesBanner,ServicesList} from '../../Routes/Imports'

const Services:React.FC = () => {
	return (
		<>
			<ServicesBanner />
			<ServicesList />
		</>
	);
};

export default Services;
