import React from "react";
import {
  DoctorInfoCard,
  DoctorListBanner,
  DoctorProfile,
  Team,
} from "../../../Routes/Imports";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getDoctorsprofile } from "../../../../Api/WebsiteApi";

const DoctorsContainer: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();

  const {
    data: doctorList,
    error: doctorError,
    isLoading: doctorLoading,
  }: any = useQuery(["DoctorProfile", id], () => getDoctorsprofile(id), {});

  return (
    <>
      <DoctorListBanner doctor_name={doctorList?.user?.name} />
      <DoctorProfile />
      <DoctorInfoCard />
      <Team />
    </>
  );
};

export default DoctorsContainer;
