import React from 'react'
import {NewsBanner, NewsList} from '../../Routes/Imports'

const NewsEvents:React.FC = () => {
  return (
    <>
    <NewsBanner/>
    <NewsList/>
    </>
  )
}

export default NewsEvents
