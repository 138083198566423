import { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container,
  Button,
} from "reactstrap";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();

  return (
    <>
      <header className="nav-bar">
        <Container>
          <Navbar className="navbar-expand-lg sm-center">
            <NavLink to="/" className={"navbar-brand"}>
              <img src="/images/logo-blue.svg" alt="Logo" />
            </NavLink>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="mx-auto" navbar>
                <NavItem>
                  <Link className={"nav-link"} to="/about-us">
                    About Us
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className={"nav-link"} to="/services">
                    Services
                  </Link>
                </NavItem>
                <NavItem>
                   <Link className={"nav-link"} to="/doctors">
                    Our Doctors
                  </Link>
                </NavItem>
                 <NavItem>
                   <Link className={"nav-link"} to="/contact-us">
                   Contact Us
                   </Link>
                </NavItem>
                {/* <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Options
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>Option 1</DropdownItem>
                      <DropdownItem>Option 2</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>Reset</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                <NavItem>
                </NavItem>
              </Nav>
              <Button
                    type="button"
                    outline
                    className="sm book-appointment"
                    onClick={() => navigate("/book/appoinment")}
                  >
                    Book An Appointment
                  </Button>
            </Collapse>
          </Navbar>
        </Container>
      </header>
    </>
  );
};

export default Header;
