import React from 'react';
import { Route } from 'react-router-dom';
import BrowserRouterProvider from './BrowserRouterWrap';
import { publicRoutes } from './RoutesData/PublicRoutes';
import { routeType } from '../../Type';

const RoutesHandler = () => {
	return (
		<BrowserRouterProvider>
			{publicRoutes?.map((routes: routeType, i: number) => (
				<Route key={i} path={routes?.path} element={routes?.element} />
			))}
		</BrowserRouterProvider>
	);
};

export default RoutesHandler;
