import * as Yup from 'yup';

export const validateEmail = (email: string | undefined) => {
    return Yup.string().email().isValidSync(email)
};

export const validatePhone = (phone: number | undefined) => {
    return Yup.number().integer().positive().test(
        (phone: any) => {
            return (phone && phone.toString().length >= 10 && phone.toString().length <= 10) ? true : false;
        }
    ).isValidSync(phone);
};