import { Outlet } from 'react-router-dom';
import Header from './Header/header';
import Footer from './Footer/footer';

const LayoutContainer:React.FC = () => {
	return (
		<>
			<Header />
			<div>
				<Outlet />
			</div>
			<Footer />
		</>
	);
};

export default LayoutContainer;
