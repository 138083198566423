import React from "react";
import { Spinner } from "reactstrap";

const CustomSpinner = () => {
  return (
    <>
      <Spinner
        as="span"
        variant="light"
        size="sm"
        role="status"
        aria-hidden="true"
        animation="border"
      />
    </>
  );
};

export default CustomSpinner;
