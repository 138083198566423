// eslint-disable-next-line no-sparse-arrays
const gender_data: any = [
  {
    value: "male",
    label: "Male",
  },
  ,
  {
    value: "female",
    label: "Female",
  },
  {
    value: "other",
    label: "Other",
  },
];

export { gender_data };
