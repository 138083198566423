function processJsonResponse(data: any, fields = ["id", "name"]) {
  let dataProcessedResponse = data?.map(function (obj: any) {
    obj["value"] = obj[fields[0]];
    obj["label"] = obj[fields[1]];
    delete obj[fields[0]];
    delete obj[fields[1]];
    return obj;
  });
  return dataProcessedResponse;
}

function shift_time(week_day: any) {
  return week_day === 7
    ? "Sunday"
    : week_day === 1
    ? "Monday"
    : week_day === 2
    ? "Tuesday"
    : week_day === 3
    ? "Wednesday"
    : week_day === 4
    ? "Thursday"
    : week_day === 5
    ? "Friday"
    : week_day === 6
    ? "Saturday"
    : "";
}

export const capitalizeFirstLetterOfEachWord = (input: string): string => {
  return input
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
export { processJsonResponse, shift_time };
