import { ErrorMessage } from "formik";
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  TabPane,
  Label,
} from "reactstrap";
import TextError from "../../../Components/Errors/TextErrors";
import { gender_data } from "../../../Utils/Constants/selection_options";
import Select from "react-select";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import AppointmentBooking from "../../../Components/TimeSlots/TimeCalanderSlots";
import moment from "moment";
import CustomButton from "../../../Components/Button/CustomButton";
import { getDoctorsFullListSelect, getDoctorsprofile } from "../../../Api/WebsiteApi";
import { useQuery } from "react-query";

interface AppointmentProps {
  userType: string;
  departmentList: any;
  // doctor: any;
  // doctorLoading: any;
  departmentLoading: any;
  genderType: any;
  values: any;
  handleSubmit: any;
  handleBlur: Function;
  isSubmitting: boolean;
  errors: any;
  handleChange: any;
  touched: any;
  setFieldValue: any;
  isOpen: any;
  setIsOpen: any;
  setSlotValue: any;
  doctorSlot: any;
  props_time: any;
}
const NewUserAppointment: React.FC<AppointmentProps> = ({
  userType,
  departmentList,
  departmentLoading,
  genderType,
  values,
  handleChange,
  touched,
  errors,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setIsOpen,
  isOpen,
  setSlotValue,
  doctorSlot,
  props_time,
}) => {


  const handleDate = (selectedDate: any, selectedTime: any) => {
    const date = moment(selectedDate).format("YYYY-MM-DD");
    const time = moment(selectedTime, "hh:mm").format("hh:mm A");

    setFieldValue("date_time", date + " " + time);
    setIsOpen(false);
  };

  //format
  const formattedPropsTime = !props_time?.time ? "" :
    moment(`${props_time.date} ${props_time.time}`, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD hh:mm A")
  const id = props_time?.doctor
  const {
    data: doctorList,
    error: doctorError,
    isLoading: doctornameLoading,
  }: any = useQuery(["DoctorProfile", props_time?.doctor], () => getDoctorsprofile(id), {});


  // Doctor List
  const { data: doctor, isLoading: doctorLoading }: any = useQuery(
    ["DoctorSelect", values?.department_id],
    () => getDoctorsFullListSelect(values?.department_id),
    {
      staleTime: 15000,
    }
  );

  useEffect(() => {
    // Check if doctorList is available and set the state
    if (doctorList && doctorList?.department?.id) {
      setFieldValue("department_id", doctorList?.department?.id);
      setFieldValue("doctor_id", id);
      setFieldValue("date_time", formattedPropsTime ? formattedPropsTime : "")
    }
  }, [doctorList]);


  return (
    <>
      <TabPane tabId="1">
        <Form onSubmit={handleSubmit}>
          <Row className="gy-4">
            {userType !== "new-user" && (
              <>
                <Col md={6}>
                  <div className="flex-between">
                    <FormGroup className="form-group flex-50">
                      <Input
                        type="select"
                        name="op_id"
                        id="op_id"
                        placeholder="Op ID"
                        value={values?.patient_id}
                        onChange={handleChange}
                        className={`form-control ${touched.password && errors.password
                          ? "is-invalid"
                          : ""
                          }`}
                      >
                        <option value="">OP ID</option>
                      </Input>
                    </FormGroup>
                  </div>
                  <ErrorMessage name="op_id" component={TextError} />
                </Col>
              </>
            )}

            <Col md={12}>
              <FormGroup className="form-group">
                <Label>Patient Name</Label>
                <Input
                  type="text"
                  name="patient_name"
                  id="patient_name"
                  placeholder="Full name"
                  value={values?.patient_name}
                  onChange={handleChange}
                  className={`form-control ${touched.patient_name && errors.patient_name
                    ? "is-invalid"
                    : ""
                    }`}
                />
              </FormGroup>
              <ErrorMessage name="patient_name" component={TextError} />
            </Col>
            <Col md={6}>
              <div className="flex-between">
                <FormGroup className="form-group flex-50">
                  <Label>Patient Mobile</Label>
                  <Input
                    type="tel"
                    name="patient_mobile"
                    id="patient_mobile"
                    placeholder="Phone number"
                    value={values?.patient_mobile}
                    onChange={handleChange}
                    className={`form-control ${touched.patient_mobile && errors.patient_mobile
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                </FormGroup>
              </div>
              <ErrorMessage name="patient_mobile" component={TextError} />
            </Col>

            <Col md={6}>
              <Label>Patient Gender</Label>
              <Input
                id="exampleSelect"
                name="patient_gender"
                type="select"
                value={values?.patient_gender}
                onChange={handleChange}
              >
                <option value="">Gender</option>
                {gender_data?.map((gen: any, i: number) => (
                  <option value={gen?.value} key={i}>
                    {gen?.label}
                  </option>
                ))}
              </Input>
              <ErrorMessage name="patient_gender" component={TextError} />
            </Col>

            <Col md={6}>
              <FormGroup className="form-group">
                <Label>Patient Email</Label>
                <Input
                  type="email"
                  name="patient_email"
                  id="patient_email"
                  placeholder="Email"
                  value={values?.patient_email}
                  onChange={handleChange}
                  className={`form-control ${touched.patient_email && errors.patient_email
                    ? "is-invalid"
                    : ""
                    }`}
                />
              </FormGroup>
              <ErrorMessage name="patient_email" component={TextError} />
            </Col>
            <Col md={6}>
              <Label>Department</Label>
              {doctorList ? (

                <Input
                  type="text"
                  value={doctorList?.department?.name}

                  className={`${touched.department_id && errors.department_id ? "is-invalid" : ""}`}
                />

              ) : (
                <Select
                  placeholder="Select Department"
                  name="department_id"
                  options={departmentList}
                  value={
                    departmentList?.filter(
                      (itm: any) => itm?.value === values?.department_id
                    ) || ""
                  }
                  onChange={(e: any) => setFieldValue("department_id", e?.value)}
                  isLoading={departmentLoading}
                  loadingMessage={() => "Loading"}
                  noOptionsMessage={() => "Departments appear here"}
                  isSearchable
                  isClearable
                  className={` ${touched.department_id && errors.department_id ? "is-invalid" : ""}`}
                />
              )}
              <ErrorMessage name="department_id" component={TextError} />
            </Col>

            <Col md={6}>
              <div className="flex-between">
                <FormGroup className="form-group flex-50">
                  <Label>Doctor</Label>
                  {doctorList ? (

                    <Input
                      type="text"
                      value={doctorList?.user?.name}

                      className="form-control"

                    />
                  ) : (

                    <Select
                      placeholder="Select Doctor"
                      name="doctor_id"
                      options={doctor}
                      value={doctor?.filter(
                        (itm: any) => itm?.value === values?.doctor_id
                      )}
                      onChange={(e: any) => {
                        setFieldValue("doctor_id", e?.value);
                        setSlotValue(e?.value);
                      }}
                      isLoading={doctorLoading}
                      loadingMessage={() => "Loading"}
                      noOptionsMessage={() => "Doctors appear here"}
                      isSearchable
                      isClearable
                    />
                  )}
                  <ErrorMessage name="doctor_id" component={TextError} />
                </FormGroup>
              </div>
            </Col>

            <Col md={6}>
              <div className="flex-between">
                <FormGroup className="form-group flex-50">
                  <Label>Booking Time & Date</Label>
                  <Input
                    type="text"
                    name="date_time"
                    id="date_time"
                    placeholder="Appointments Date & Time"
                    value={values.date_time ? values.date_time : formattedPropsTime}
                    onChange={handleChange}
                    className={`form-control ${touched.date_time && errors.date_time ? "is-invalid" : ""
                      }`}
                    onClick={() => setIsOpen(true)}
                  />

                </FormGroup>
              </div>
              <ErrorMessage name="date_time" component={TextError} />
            </Col>

            <Col md={12}>
              <FormGroup className="form-group">
                <Label>Message</Label>
                <Input
                  type="textarea"
                  id="text"
                  placeholder="Message"
                  name="message"
                  value={values?.message}
                  onChange={handleChange}
                  className={`form-control ${touched.message && errors.message ? "is-invalid" : ""
                    }`}
                />
              </FormGroup>
              <ErrorMessage name="message" component={TextError} />
            </Col>

            <Col lg={12}>
              <CustomButton
                label="Get Your Appointment"
                className="w-100 md"
                isLoading={isSubmitting}
              />
            </Col>
          </Row>
        </Form>
      </TabPane>

      <ModalContainer
        isOpen={isOpen}
        setIsopen={setIsOpen}
        title="Appointment Date & Time"
      >
        <AppointmentBooking
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          handleDate={handleDate}
          doctorSlot={doctorSlot}
        />
      </ModalContainer>
    </>
  );
};

export default NewUserAppointment;
