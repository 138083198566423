import { useState } from "react";
import { Button, CardBody, Col, Row } from "reactstrap";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DigitalClock } from "@mui/x-date-pickers/DigitalClock";
import { shift_time } from "../../Utils/helpers/helpers";
import moment from "moment";
import { toast } from "react-toastify";
import ModalContainer from "../Modal/ModalContainer";
import TimeSlotConfirmModal from "../../Pages/Public/Bookappointments/TimeSlotConfirmModal";

const AppointmentBooking = ({
  isOpen,
  setIsOpen,
  handleDate,
  doctorSlot,
}: any) => {
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedTime, setSelectedTime] = useState<any>();
  const [time_slotConfirm, setTimeSLotModal] = useState(false);

  const groupedByDay = doctorSlot?.reduce((result: any, item: any) => {
    const { week_day, ...rest } = item;
    if (!result[week_day]) {
      result[week_day] = [];
    }
    result[week_day]?.push(rest);
    return result;
  }, {});

  const groupedArray = Object?.entries(groupedByDay)?.map(([key, value]) => ({
    week_day: parseInt(key, 10),
    data: value,
  }));

  const getCurrentDay = () => {
    return moment().isoWeekday(); // 1 for Monday, 2 for Tuesday, etc.
  };

  const currentDay = getCurrentDay();

  const filteredTimeSlots = groupedArray?.filter(
    (slot: any) => slot?.week_day >= moment(selectedDate)?.isoWeekday()
  );
  const _validateTimeDate = () => {
    if (selectedDate === null || selectedTime === null) {
      toast.error("Select Booking Time & Booking Date");
    } else {
      setTimeSLotModal(true);
    }
  };
  console.log(selectedTime, "selectedTime");

  return (
    <>
      <div className="form-wrap">
        <Row className="gy-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["StaticDatePicker"]}>
              <Col lg={12}>
                <DemoItem label="Take Booking Date">
                  <StaticDatePicker
                    onChange={(e: any) => {
                      setSelectedDate(e?.$d);
                    }}
                    defaultValue={dayjs(new Date())}
                    disablePast
                  />
                </DemoItem>
              </Col>
            </DemoContainer>
          </LocalizationProvider>

          <Col lg={12}>
            {filteredTimeSlots?.length === 0 ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["StaticTimePicker"]}>
                  <DemoItem label="Take Booking Time">
                    <DemoItem>
                      <DigitalClock
                        disablePast={selectedDate < new Date() ? true : false}
                        minTime={dayjs("2022-04-17T09:00")}
                        timeStep={10}
                        onChange={(e: any) => {
                          if (e?.$d instanceof Date) {
                            setSelectedTime(e.$d);
                          }
                        }}
                      />
                    </DemoItem>
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            ) : (
              <div className="clinic-appointment-wrap">
                <div className="availability-buttons-full-wrap">
                  <div className="availability-inner">
                    <div className="item">
                      {filteredTimeSlots?.map((item: any) => {
                        return (
                          <>
                            <h6>
                              {shift_time(item?.week_day)}
                              <span>({item?.data?.length} slots)</span>
                            </h6>
                            <div className="flex-wrap appointment-popup-flex-wrap">
                              {item?.data?.map((items: any) => {
                                return (
                                  <>
                                    <Button
                                      color="primary-100"
                                      outline
                                      className="sm"
                                      onClick={() => {
                                        setSelectedTime(items?.time_start);
                                      }}
                                    >
                                      {moment(
                                        items?.time_start,
                                        "hh:mm"
                                      ).format("hh:mm A")}{" "}
                                    </Button>
                                    {/* <Button
                                      color="primary-100"
                                      outline
                                      className="sm active px-3"
                                      onClick={() => {
                                        setSelectedTime(items?.time_end);
                                      }}
                                    >
                                    </Button> */}
                                  </>
                                );
                              })}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <div className="button-wrap end">
          <Button
            color="secondary"
            className="sm full-width"
            outline
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            className="sm full-width"
            onClick={() => _validateTimeDate()}
          >
            Select
          </Button>
        </div>
      </div>
      <ModalContainer
        isOpen={time_slotConfirm}
        setIsopen={setTimeSLotModal}
        title="Appointment Date & Time"
      >
        <TimeSlotConfirmModal
          setIsOpen={setTimeSLotModal}
          isOpen={time_slotConfirm}
          handleDate={handleDate}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          filteredTimeSlots={filteredTimeSlots}
        />
      </ModalContainer>
    </>
  );
};

export default AppointmentBooking;
