import React from 'react'
import { Container, Col } from 'reactstrap';

const PrivacyPolicyList = () => {
  return (
    <>
           <section className="privacy-policy">
            <div className="privacy-policy-outer-wrap bg-transparent">
                <Container>
                    <div className="section-heading-wrap">
                        <div className="left-side">
                            <h6 className='sub-heading'>Caring For Your Data</h6>
                            <h4 className='main-heading'>Privacy Policy</h4>
                        </div>
                    </div>
                    <div className="privacy-policy-inner-wrap">
                        <Col lg={12} md={12} sm={12}>
                            <h5>Introduction</h5>
                            <p>Welcome to Sabitha eye care hospital! We value your trust and are committed to protecting your privacy. This Privacy Policy is designed to inform you about how we collect, use, disclose, and safeguard your personal information when you interact with our services, including our website, mobile applications, and in-person interactions. By using our services, you consent to the practices described in this policy.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Information We Collect</h5>
                            <p>We collect personal and non-personal information for medical services, communication, and improvement purposes.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>How We Use Your Information</h5>
                            <p>We use your information for appointments, communication, service improvement, and legal compliance.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Disclosure of Your Information</h5>
                            <p>We share information with healthcare providers, service providers, and for legal and business purposes.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Your Privacy Choices</h5>
                            <p>You can access, correct, or opt out of marketing communications. Manage cookies through your browser settings.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Security</h5>
                            <p>We take the security of your information seriously and employ reasonable safeguards to protect it from unauthorized access, disclosure, alteration, or destruction.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Children's Privacy</h5>
                            <p>Our services are not intended for individuals under the age of 18. We do not knowingly collect or maintain information from children.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Changes to this Policy</h5>
                            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for legal, regulatory, or operational reasons. We will notify you of any material changes through our website or other means.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Contact Us</h5>
                            <p>If you have any questions, concerns, or requests related to this Privacy Policy or your personal information, please contact us at 91-80-22225645.</p>
                            <p>Thank you for choosing Sabitha eye care hospital for your eye care needs. Your privacy is important to us, and we are committed to safeguarding your information.</p>
                        </Col>
                    </div>
                </Container>

            </div>
        </section>
    </>
  )
}

export default PrivacyPolicyList
