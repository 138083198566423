import React from "react";

import {
    OuterBanner,
    InfoBanner,
    AboutCards,
    MultipleCards,
    DoctorReview,
    Team
} from '../../Routes/Imports'

const About:React.FC =()=> {
    return (
        <>
            <OuterBanner/>
            <InfoBanner/>
            <AboutCards/>
            <MultipleCards/>
            <DoctorReview/>
            <Team/>
        </>
    )
};
export default About;