import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutContainer from '../../../Components/Layouts';
import { childrenType } from '../../../Type';

const BrowserRouterProvider:React.FC<childrenType> = ({ children} ) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<LayoutContainer />}>
                    {children}
                    <Route path='*' element={<h3 className='empty-page-text'>Page Not Found</h3>} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default BrowserRouterProvider