import React from 'react';
import { 
Container, 
Row, 
Col, 
Card,
CardBody,
CardTitle,
CardText
} from 'reactstrap';

const MultipleCards:React.FC =()=> {
    return (
        <>
        <section className="counts">
            <div className="count-wrap">
                <Container>
                    <Row>
                        <Col lg={3} md={3} sm={6} xs={12} className='reveal'>
                            <Card className='count-card'>
                                <CardBody>
                                    <CardTitle tag="h5" className='count-title'>
                                        10
                                    </CardTitle>
                                    <CardText className='count-desc'>
                                        Years of experience
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={12} className='reveal'>
                            <Card className='count-card'>
                                <CardBody>
                                    <CardTitle tag="h5" className='count-title'>
                                        100+
                                    </CardTitle>
                                    <CardText className='count-desc'>
                                        Reviews
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={12} className='reveal'>
                            <Card className='count-card'>
                                <CardBody>
                                    <CardTitle tag="h5" className='count-title'>
                                        400 +
                                    </CardTitle>
                                    <CardText className='count-desc'>
                                        Happy clients
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={12} className='reveal'>
                            <Card className='count-card'>
                                <CardBody>
                                    <CardTitle tag="h5" className='count-title'>
                                        20+
                                    </CardTitle>
                                    <CardText className='count-desc'>
                                        Achievements
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
        </>

    );
}

export default MultipleCards;