import React from "react";
import { useQuery } from "react-query";
import { Container, Row, Col, Card, CardTitle } from "reactstrap";
import { getServicesFullList } from "../../../../Api/WebsiteApi";
import { CardTypesService } from "../../../../Type";

const ServicesList: React.FC = () => {
  const {
    data: serviceList,
    error: serviceError,
    isLoading: serviceLoading,
  }: any = useQuery("service", () => getServicesFullList(), {});

  return (
    <>
      <section className="services-grid-list">
        <div className="services-outer-wrap">
          <Container>
            <div className="services-inner-wrap">
              {serviceList?.map((service: CardTypesService, index: number) => (
                <Card className="service-card-variant one">
                  <Row className="service-row">
                    <Col lg={2} className="colored" style={{ backgroundColor: service?.color }}>
                      <div className="icon-wrap">
                        <img
                          className="thumbnail-xs-contain"
                          src={service?.image_url}
                          alt="image"
                        />
                      </div>
                    </Col>
                    <Col lg={8} className="centered">
                      <div className="services-desc">
                        <CardTitle tag="h5">{service?.title}</CardTitle>
                        <p>{service?.description}</p>
                      </div>
                    </Col>
                    {/* <Col lg={2} className='centered'>
                                            <div className="view-link">
                                                <a href="">Consult Now <i className='icon icon-arrow-right'></i></a>
                                            </div>
                                        </Col> */}
                  </Row>
                </Card>
              ))}
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default ServicesList;
