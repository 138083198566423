import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
    List,
} from 'reactstrap';

const AboutCards: React.FC = () => {
    return (
        <>
            <section className="about-us-more">
                <div className="about-us-detail-cards">
                    <Container>
                        <Row>
                            <Col lg={4} md={4} className='colored-card-col reveal'>
                                <Card className='colored-card'>
                                    <CardBody className='colored-card-wrap'>
                                        <CardTitle tag="h4">
                                            Why Sabitha Care
                                        </CardTitle>
                                        <CardText>
                                            Step into the world of Sabitha Eye Care Hospital, a distinguished pioneer in comprehensive eye wellness. With a decade-long legacy of brilliance, we're devoted to safeguarding and elevating the invaluable blessing of vision.
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={4} md={4} className='colored-card-col reveal'>
                                <Card className='colored-card'>
                                    <CardBody className='colored-card-wrap'>
                                        <CardTitle tag="h4">
                                            Mission
                                        </CardTitle>
                                        <CardText>
                                            <List>



                                                <li>
                                                    The website will provide its users with accurate, up-to-date information about health and wellness, as well as tools and resources to help them manage their health.
                                                </li>
                                                <li>
                                                    The website will be easy to use and navigate, and it will be available to users of all income levels and backgrounds.
                                                </li>
                                                <li>
                                                    The website will be a trusted source of support and information for patients and their families.
                                                </li>
                                            </List>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={4} md={4} className='colored-card-col reveal'>
                                <Card className='colored-card'>
                                    <CardBody className='colored-card-wrap'>
                                        <CardTitle tag="h4">
                                            Vision
                                        </CardTitle>
                                        <CardText>
                                            <List>
                                                <li>
                                                    The website will work to make healthcare more accessible to everyone, regardless of their income, location, or health status.
                                                </li>
                                                <li>
                                                    The website will continue to provide its users with accurate, up-to-date information about health and wellness, as well as tools and resources to help them manage their health.
                                                </li>
                                                <li>
                                                    The website will work to make healthcare more affordable for everyone. This may involve advocating for changes to public policy, or working with healthcare providers to offer more affordable options.
                                                </li>
                                            </List>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>

    );
}

export default AboutCards;