import { useLocation } from "react-router-dom";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import Appointments from "../Bookappointments/AppointmentsContainer";

export default function AppointmentBook(props: any) {
  const location = useLocation();
  return (
    <>
      <div className="bookings-page">
        <Appointments doctor={location?.state?.doctor} time={location?.state?.time} date={location?.state?.date} />
      </div>
    </>
  );
}
