import React from 'react';
import { 
Container, 
Row, 
Col, 
Card,
} from 'reactstrap';

const DoctorReview:React.FC=()=> {
    return (
        <>
        <section className="doctor-review">
            <div className="doctor-review-wrap reveal">
                <Container>
                    <Row className='align-items-center'> 
                        <Col lg={2} md={2} xs={12}>
                            <Card className='doctor-image-card'>
                                <div className="doctor-thumbnail-wrap">
                                    <img src="/images/doctor1.webp"  className="thumbnail-md-cover" alt="Doctor's" />
                                </div>
                            </Card>
                        </Col>

                        <Col lg={10} md={10} xs={12}>
                            <div className="doctor-review-details">
                                <div className="name-and-details">
                                <h4>Alen Mathew</h4>
                                <h6>Clinic Director</h6>
                                <h6>Visionary Eye Care, Redefined.</h6>
                                </div>
                                <div className='review'>
                                    <p>Step into the world of Sabitha Eye Care Hospital, a distinguished pioneer in comprehensive eye wellness. With a decade-long legacy of brilliance, we're devoted to safeguarding and elevating the invaluable blessing of vision. Our unwavering commitment to progress, cutting-edge technology, and a team of exceptionally skilled ophthalmologists positions us as the ultimate hub for all things related to your ocular health.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
        </>

    );
}

export default DoctorReview;