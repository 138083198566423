import { FormikHelpers } from "formik";
import { api } from "../../../../Api/Api";
import { toast } from "react-toastify";

type AddInitialValues = {
  name: string;
  doctor_id: string;
  email: string;
  subject: string;
  message: string;

};

export const AddFeedbackSubmitHandler = (
  values: AddInitialValues,
  actions: FormikHelpers<AddInitialValues>,
  setIsOpen: Function,
  doctor_id: string,
  Refetch:any
) => {
  if (doctor_id !=="undefined") {
    // Use the `/feedback` endpoint when `doctor_id` is present
    values.doctor_id=doctor_id

    api
      .post(`/public/doctor-feedbacks`, values, true)
      .then(async function ([success, response]: any) {
        toast.success("Feedback added");
        setIsOpen(false);
        Refetch()
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err?.response?.data?.errors) {
          // Handle errors if needed
        } else {
          toast.error("Unexpected Error Occurred");
        }
      });
  } else {
 
    api
    .post(`/public/feedbackCreate`, values, true)
    .then(async function ([success, response]: any) {
      toast.success("Feedback added");
      setIsOpen(false);
    })
    .catch((err) => {
      actions?.setSubmitting(false);
      if (err?.response?.data?.errors) {
        // Handle errors if needed
      } else {
        toast.error("Unexpected Error Occurred");
      }
    });
  }
};
