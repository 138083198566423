import React, { useLayoutEffect, useState } from "react";
import { Container, Row, Button } from "reactstrap";
import { CardTypesDoc } from "../../../../Type/index";
import { api } from "../../../../Api/Api";
import DocCards from "../../../../Components/Cards/DocCard";
import { useQuery } from "react-query";
import {
  getDoctorsFullList,
  getDoctorsFullListAll,
} from "../../../../Api/WebsiteApi";
import { getDepartmentFullList } from "../../../../Api/WebsiteApi";

const DoctorList: React.FC = () => {
  const [status, setStaus] = useState("");
  const {
    data: doctorList,
    error: doctorError,
    isLoading: doctorLoading,
  }: any = useQuery(
    ["Doctor", status],
    () => getDoctorsFullListAll(status),
    {}
  );

  // Department List
  const {
    data: departmentList,
    error: departmentError,
    isLoading: departmentLoading,
  }: any = useQuery("department", () => getDepartmentFullList(), {});

  const handleDepartmentChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedDepartment = event.target.value;
    setStaus(event.target.value);
  };
  console.log(status,"status")

  return (
    <>
      <section className="team">
        <div className="team-home-outer-wrap">
          <Container>
            <div className="section-heading-wrap">
              <div className="left-side-wrap">
                <h6 className="sub-heading">Caring Hands And Expertise</h6>
                <h4 className="main-heading">Meet Our Specialists</h4>
              </div>
              <div className="filter-side-wrap">
                <div className="filter-wrap">
                  <select
                    name="department"
                    id="department"
                    className="form-select"
                    onChange={handleDepartmentChange}
                  >
                    <option value="" disabled selected>
                      Select Department
                    </option>
                    {departmentList?.map((department: any) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                  </select>
                  {/* <select name="" id="" className="form-select">
                    <option value="" selected disabled>
                      Type Of Consultancy
                    </option>
                  </select>
                  <select name="" id="" className="form-select">
                    <option value="" selected disabled>
                      Availability
                    </option>
                  </select> */}
                  {/* <Button color="secondary" outline className="md">
                    <i className="icon icon-filter xl"></i>
                  </Button> */}
                  <Button color="secondary" outline className="md">
                    <i className=" icon icon-sort xl "></i>
                  </Button>
                </div>
              </div>
            </div>
          </Container>

          {/* cards section */}
          <div className="team-list-outer-wrap">
            <Container>
              <Row className="gy-4">
                {doctorList?.map((doctor: CardTypesDoc) => (
                  <DocCards
                    props={doctor}
                    key={doctor?.id}
                    to={`/doctor/profile`}
                  />
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default DoctorList;
