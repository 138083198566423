import React, { useState } from "react";
import { useQuery } from "react-query";
import Slider from "react-slick";
import { Container, Button, CardHeader, Card, CardBody } from "reactstrap";
import { getSlots } from "../../Api/WebsiteApi";
import moment from "moment";
import CustomSpinner from "../Button/CustomSpinner";
import {

  getWeekDates,

} from "./TimeCalenderMethods";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface TimeCalenderProps {
  doctor: string;
}

const TimeCalender: React.FC<TimeCalenderProps> = ({ doctor }) => {
  // Fetch doctor's slots
  const { data: doctorSlot, isLoading: doctorSlotLoading } = useQuery(
    ["DoctorSlots", doctor],
    () => getSlots(doctor)
  );
  const getCurrentDay = () => {
    return moment().isoWeekday(); // 1 for Monday, 2 for Tuesday, etc.
  };

  const currentDay = getCurrentDay();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [slide, setSlide] = useState(0);

  const handleBeforeChange = (currentSlide: any, nextSlide: any) => {
    setSelectedDate(weekDates[nextSlide]?.date);

  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: handleBeforeChange,
    afterChange: function (currentSlide: any) {
      setSlide(currentSlide);
    },
  };


  const groupedByDay = doctorSlot
    ? doctorSlot.reduce((result: any, item: any) => {
      const { week_day, ...rest } = item;
      if (!result[week_day]) {
        result[week_day] = [];
      }
      result[week_day]?.push(rest);
      return result;
    }, {})
    : {};

  const groupedArray = Object.entries(groupedByDay).map(([key, value]) => ({
    week_day: parseInt(key, 10),
    data: value,
  }));


  const currentDate = moment().format("MMMM D, YYYY");

  const filteredTimeSlots: any[] = groupedArray
    ?.filter((slot: any) => slot?.week_day === slide)
    .map((slot: any) => slot.data)
    .flat();


  // Define time slot boundaries for morning, afternoon, and evening
  const morningStart = moment("06:00:00", "HH:mm:ss");
  const afternoonStart = moment("12:00:00", "HH:mm:ss");
  const eveningStart = moment("16:00:00", "HH:mm:ss");

  // Filter slots based on time of day
  const morningSlots = filteredTimeSlots
    .filter((slot: any) =>
      moment(slot?.time_start, "HH:mm:ss").isBefore(afternoonStart)
    );
  const afternoonSlots = filteredTimeSlots
    .filter((slot: any) =>
      moment(slot?.time_start, "HH:mm:ss").isBetween(afternoonStart, eveningStart)
    );
  const eveningSlots = filteredTimeSlots
    .filter((slot: any) =>
      moment(slot?.time_start, "HH:mm:ss").isAfter(eveningStart)
    );

  const weekDates: any = getWeekDates();


  const week_data = [
    { id: 1, name: "Monday", date: weekDates?.[1] },
    { id: 2, name: "Tuesday", date: weekDates?.[2] },
    { id: 3, name: "Wednesday", date: weekDates?.[3] },
    { id: 4, name: "Thursday", date: weekDates?.[4] },
    { id: 5, name: "Friday", date: weekDates?.[5] },
    { id: 6, name: "Saturday", date: weekDates?.[6] },
    { id: 7, name: "Sunday", date: weekDates?.[0] },
  ];


  const go_bookk = (time: any, date: any) => {
    if (time === null) {
      toast.error("Please Select a Booking Time");
    } else {
      navigate("/book/appoinment", {
        state: {
          doctor: doctor,
          time: selectedTime,
          date: selectedDate
        },
      });
    }
  };


  return (
    <>
      <Card className="clinic-appointment-wrap">
        <CardHeader>
          <h6>Clinic Appointment</h6>
        </CardHeader>
        <div className="slider-wrap row">
          <Slider {...settings}  >
            {weekDates?.map((item: any) => {

              return (
                <Button
                  color="transparent"
                  className="availability-head md"
                  key={item.id}

                >
                  <h6 className="day">{item?.name}</h6>
                  <h6>{item?.date}</h6>
                </Button>
              );
            })}
          </Slider>


        </div>
        <CardBody>
          <div className="availability-buttons-full-wrap">
            <div className="availability-inner">
              <div className="item">
                {doctorSlotLoading ? (<div><CustomSpinner /></div>) : (
                  <CardBody>
                    <div className="availability-buttons-full-wrap">
                      <div className="availability-inner">
                        <div className="item">
                          <h6>Morning <span className="slots">({morningSlots.length} slots)</span></h6>

                          <div className="flex-wrap">
                            {morningSlots.map((items: any) => (
                              <><Button
                                color="primary-100"
                                outline
                                className="sm"
                                onClick={() => {
                                  setSelectedTime(items?.time_start);
                                }}
                              >
                                {moment(items?.time_start, "HH:mm:ss").format("hh:mm A")}
                              </Button>

                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="availability-inner">
                        <div className="item">
                          <h6>Afternoon <span className="slots">({afternoonSlots.length} slots)</span></h6>

                          <div className="flex-wrap">
                            {afternoonSlots.map((items: any) => (
                              <><Button
                                color="primary-100"
                                outline
                                className="sm"
                                onClick={() => {
                                  setSelectedTime(items?.time_start);
                                }}
                              >
                                {moment(items?.time_start, "HH:mm:ss").format("hh:mm A")}
                              </Button>

                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="availability-inner">
                        <div className="item">
                          <h6>Evening <span className="slots">({eveningSlots.length} slots)</span></h6>

                          <div className="flex-wrap">
                            {eveningSlots.map((items: any) => (
                              <><Button
                                color="primary-100"
                                outline
                                className="sm"
                                onClick={() => {
                                  setSelectedTime(items?.time_start);
                                }}
                              >
                                {moment(items?.time_start, "HH:mm:ss").format("hh:mm A")}
                              </Button>

                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                  </CardBody>)}
              </div>
            </div>
            { }
            <div className="appointment-btn">
              <Button
                color="secondary"
                className="sm"
                onClick={() => {
                  go_bookk(selectedTime, selectedDate);
                }}
              >
                Book Appointment
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default TimeCalender;
