import React, { useState } from "react";
import {
    Container,
    Row,
    Col,
    List,
    Button,
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Appointments from "../../Bookappointments/AppointmentsContainer";

const InfoBanner: React.FC = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    return (
        <>
            <section className="about-us">
                <div className="about-us-main-wrap">
                    <Container>
                        <Row>
                            <Col lg={6} xs={12}>
                                <Card>
                                    <div className="about-thumbnai-wrap">
                                        <img
                                            className="thumbnail-lg-cover"
                                            src="/images/about-img.webp"
                                            alt="image"
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={6} xs={12}>
                                <div>
                                    <div className="section-heading-wrap">
                                        <div className="heading-wrap">
                                            <h6 className="sub-heading">Who we are</h6>
                                            <h4 className="main-heading text-primary">
                                                We are serving expert eye specialists
                                            </h4>
                                        </div>
                                    </div>
                                    <p>
                                        Step into the world of Sabitha Eye Care Hospital, a a
                                        distinguished pioneer in comprehensive eye wellness. With a
                                        decade-long legacy of brilliance, we're devoted to
                                        safeguarding and elevating the invaluable blessing of
                                        vision. Our unwavering commitment to progress, cutting-edge
                                        technology, and a team of exceptionally skilled
                                        ophthalmologists position us as the ultimate hub for all
                                        things related to your ocular health.
                                    </p>

                                    <List className='primary-list'>
                                        <li>
                                            Drawing up an individual treatment program for patients.
                                        </li>
                                        <li>
                                            Performing all operations and procedures at a professional level.
                                        </li>
                                        <li>
                                            Ensuring compliance with material and equipment international standards.
                                        </li>

                                    </List>
                                    {/* <div className="appointment-btn">
                                        <Button color='secondary' className="sm" type="submit" onClick={toggleModal}>
                                            Book An Appointment
                                        </Button>

                                    </div> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    );
};

export default InfoBanner;
