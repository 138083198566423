import React, { useLayoutEffect } from 'react'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
}from 'reactstrap';

import Cards from '../../../../Components/Cards/Card';
import { CardTypes } from '../../../../Type/index'
import { api } from '../../../../Api/Api';

const Newscard: CardTypes[] = [
    { id: 1, name: 'Free Eye Check-up', image_url: '/images/news1.webp', title: '', imgclass: 'thumbnail-md-cover', imgdiv: 'icon-wrap', cardClass: 'icon icon-facebook-colored xl', cardTitle: 'name-wrap', message: 'Experience a free eye check-up that puts your eye health first.' ,description:"" },
    { id: 2, name: 'Eye Hospital Conference', image_url:'/images/news2.webp', title: '', imgclass: 'thumbnail-md-cover', imgdiv: 'icon-wrap', cardClass: 'icon icon-instagram-colored xl', cardTitle: 'name-wrap', message: 'The Social Media Landscape - Evolving Trends And Challenges',description:""  },
    { id: 3, name: 'Eye Health Conference', image_url: '/images/news3.webp', title: '', imgclass: 'thumbnail-md-cover', imgdiv: 'icon-wrap', cardClass: 'icon icon-twitter-colored xl', cardTitle: 'name-wrap', message: 'Dengue Fewer OutBreak Raises Conserns For Public Health',description:""},
    { id: 4, name: 'Innovations in Pediatric', image_url: '/images/news4.webp', title: '', imgclass: 'thumbnail-md-cover', imgdiv: 'icon-wrap', cardClass: 'icon icon-youtube-colored xl', cardTitle: 'name-wrap', message: 'The Social Media Landscape - Evolving Trends And Challenges',description:""  },
   
];


const NewsList: React.FC = () => {

    return (
        <>
            <section className="news">
                <div className="news-outer-wrap">
                    <Container>
                        <div className="section-heading-wrap">
                            <div className="left-side">
                                <h6 className='sub-heading'>Stay Informed Stay Ahead !</h6>
                                <h4 className='main-heading'>Empowering You Eyes, Elevating Your Well Being</h4>
                            </div>
                        </div>
                    </Container>

                    {/* cards section */}
                    <div className="news-list-outer-wrap">
                        <Container>
                            <Row className='gy-4'>
                                {Newscard?.map((News)=>(

                                
                                <Col sm={6} md={6} lg={3}>
                                    <Card className='news-card'>
                                        <a href="#">
                                            <div className="thumbnail-wrap">
                                                <img
                                                    src={News.image_url}
                                                    className='thumbnail-md-cover'
                                                    alt="image"
                                                />
                                            </div>
                                            <CardBody>
                                                <CardTitle className='news-title-wrap'>
                                                    <i className={News.cardClass}></i>
                                                    <h6 className='gray'>{News.name}</h6>
                                                </CardTitle>
                                                <div className="description-wrap">
                                                    <p>{News.message}</p>
                                                </div>
                                            </CardBody>
                                        </a>
                                    </Card>
                                </Col>
                                ))
                                }
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewsList;
