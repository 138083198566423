import { ErrorMessage, Formik } from "formik";
import { Col, Form, Input, Label, Row } from "reactstrap";
import TextError from "../../../../Components/Errors/TextErrors";
import { AddFeedbackSubmitHandler } from "./FeedbackMethods";
import { AddFeedbackType } from "../../../../Type";
import {
  FeedbackInitialValues,
  FeedbackValidationSchema,
} from "./ValidationSchema";
import CustomButton from "../../../../Components/Button/CustomButton";

const AddFeedback = ({ setIsOpen, doctor_id, Refetch }: AddFeedbackType) => {
  return (
    <Formik
      validationSchema={FeedbackValidationSchema}
      initialValues={FeedbackInitialValues}
      enableReinitialize
      onSubmit={(values, actions) => {
        AddFeedbackSubmitHandler(
          values,
          actions,
          setIsOpen,
          doctor_id,
          Refetch
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        setFieldValue,
        setFieldError,
        errors,
      }) => {
        return (
          <div className="form-wrap">
            <Form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col lg={6}>
                  <Label>Full Name</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.name}
                    name="name"
                    placeholder="Full Name"
                  />
                  <ErrorMessage name="name" component={TextError} />
                </Col>
                <Col lg={6}>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    onChange={handleChange}
                    value={values?.email}
                    name="email"
                    placeholder="Email"
                  />
                  <ErrorMessage name="email" component={TextError} />
                </Col>

                <Col lg={12}>
                  <Label>Subject</Label>
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={values?.subject}
                    name="subject"
                    placeholder="Subject"
                  />
                  <ErrorMessage name="subject" component={TextError} />
                </Col>
                <Col lg={12}>
                  <Label>Message</Label>
                  <Input
                    type="textarea"
                    onChange={handleChange}
                    value={values?.message}
                    name="message"
                    placeholder="Message"
                  />
                  <ErrorMessage name="message" component={TextError} />
                </Col>
              </Row>
              <Col lg={12} className="mt-4">
                <CustomButton
                  isLoading={isSubmitting}
                  label="Submit"
                  color="secondary"
                  className="sm submit-btn"
                />
              </Col>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddFeedback;
