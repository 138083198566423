import { extractDoctorData } from "../Pages/Public/Bookappointments/AddBookingMethods";
import { processJsonResponse } from "../Utils/helpers/helpers";
import { api } from "./Api";

export async function getBannerFullList() {
  const [, data] = await api.get(`/public/banner?status=1`, true);
  return data?.data;
}
export async function getDoctorsprofile(id: string | undefined) {
  const [, data] = await api.get(`/public/doctor/${id}`, true);
  return data?.data;
}

export async function getDoctorsFullList() {
  const [, data] = await api.get(`/public/doctors`, true);
  return data?.data;
}

export async function getDoctorsFullListAll(status: any) {
  const [, data] = await api.get(`/public/doctors?dept=${status}`, true);
  return data?.data;
}

export async function getDoctorsFullListSelect(depart: any) {
  const [, data] = await api.get(`/public/doctors?is_active=1&dept=${depart}`, true);
  return extractDoctorData(data?.data);
}

export async function getAwardFullList() {
  const [, data] = await api.get(`/public/hospitalawards?status=1`, true);
  return data?.data;
}

export async function getTestimonialsList() {
  const [, data] = await api.get(`/public/testimonial?status=1`, true);
  return data?.data;
}

// departments listing
export async function getDepartmentFullList() {
  const [, data] = await api.get(`/unauthDepartment`, true);
  return data?.data;
}

export async function getDepartmentFullListSelect() {
  const [, data] = await api.get(`/unauthDepartment`, true);
  return processJsonResponse(data?.data, ["id", "name"]);
}

export async function getServicesFullList() {
  const [, data] = await api.get(`/public/service?status=1`, true);
  return data?.data;
}
export async function getSlots(doctor_id: any) {
  const [, data] = await api.get(
    `/public/doctorslots?doctor_id=${doctor_id}`,
    true
  );
  return data?.data;
}
