import moment from "moment";

function divideTimeRangesIntoSlots(scheduleArray: any) {
  const result: any = [];

  scheduleArray?.forEach((schedule: any) => {
    const { time_start, time_end } = schedule;

    const currentTime = new Date(`2023-01-01T${time_start}`);
    const endTimeObj = new Date(`2023-01-01T${time_end}`);

    while (currentTime < endTimeObj) {
      const slotEndTime = new Date(currentTime.getTime() + 30 * 60000); // 15 minutes in milliseconds

      result.push({
        startTime: currentTime.toLocaleTimeString(),
        endTime: slotEndTime.toLocaleTimeString(),
      });

      currentTime.setTime(slotEndTime.getTime());
    }
  });

  return result;
}

const filterAppointmentsByWeekDay = (appointments: any, targetWeekDay: any) => {
  return appointments?.filter(
    (appointment: any) => appointment.week_day === targetWeekDay
  );
};
const unique_data = (data: any) => {
  const uniqueTimes = new Set();

  // Filter out duplicates and create a new array
  const uniqueSlots = data?.filter((slot: any) => {
    const { startTime, endTime } = slot;

    // Check if both start time and end time are unique
    if (!uniqueTimes.has(startTime) && !uniqueTimes.has(endTime)) {
      uniqueTimes.add(startTime);
      uniqueTimes.add(endTime);
      return true;
    }

    return false;
  });

  return uniqueSlots;
};
const day_format = (data: any) => {
  const all_data = divideTimeRangesIntoSlots(data);

  return unique_data(all_data);
};

const filter_patriculr_time_mng = (data: any) => {
  const startTimePeriod = moment("09:00 AM", "hh:mm A");
  const endTimePeriod = moment("11:59 AM", "hh:mm A");

  // Filter the slots within the specified time period
  const filteredSlots = data.filter((slot: any) => {
    const startTime = moment(slot.startTime, "hh:mm A");
    const endTime = moment(slot.endTime, "hh:mm A");

    return (
      startTime.isBetween(startTimePeriod, endTimePeriod, undefined, "[]") ||
      endTime.isBetween(startTimePeriod, endTimePeriod, undefined, "[]")
    );
  });
  return filteredSlots;
};
const filter_patriculr_time_evg = (data: any) => {
  const startTimePeriod = moment("12:00 PM", "hh:mm A");
  const endTimePeriod = moment("06:00 PM", "hh:mm A");

  // Filter the slots within the specified time period
  const filteredSlots = data.filter((slot: any) => {
    const startTime = moment(slot.startTime, "hh:mm A");
    const endTime = moment(slot.endTime, "hh:mm A");

    return (
      startTime.isBetween(startTimePeriod, endTimePeriod, undefined, "[]") ||
      endTime.isBetween(startTimePeriod, endTimePeriod, undefined, "[]")
    );
  });
  return filteredSlots;
};
const filter_patriculr_time_after_noon = (data: any) => {
  const startTimePeriod = moment("06:00 PM", "hh:mm A");
  const endTimePeriod = moment("11:30 PM", "hh:mm A");

  // Filter the slots within the specified time period
  const filteredSlots = data.filter((slot: any) => {
    const startTime = moment(slot.startTime, "hh:mm A");
    const endTime = moment(slot.endTime, "hh:mm A");

    return (
      startTime.isBetween(startTimePeriod, endTimePeriod, undefined, "[]") ||
      endTime.isBetween(startTimePeriod, endTimePeriod, undefined, "[]")
    );
  });
  return filteredSlots;
};
const getWeekDates = () => {
  const currentDay = new Date().getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    
  ];

  const weekDates = daysOfWeek.map((day, index) => {
    const currentDate = new Date();
    const dayOffset = (index + 7 - currentDay) % 7;
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + dayOffset);

    // Format the date as a string
    const dateString = date.toISOString().split("T")[0]; // "YYYY-MM-DD"

    return {
      id: index + 1, // Assuming ids start from 1
      name: day,
      date: dateString,
    };
  });

  return weekDates;
};

export {
  divideTimeRangesIntoSlots,
  filterAppointmentsByWeekDay,
  day_format,
  unique_data,
  filter_patriculr_time_mng,
  filter_patriculr_time_evg,
  filter_patriculr_time_after_noon,
  getWeekDates,
};
