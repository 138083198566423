import React from 'react';
import {
  Container,
  Row,
} from 'reactstrap';

import { CardTypes } from '../../../../Type/index';
import Cards from '../../../../Components/Cards/Card';
import { getAwardFullList } from '../../../../Api/WebsiteApi';
import { useQuery } from 'react-query';



const AwardsList: React.FC = () => {

  const {
    data: awardList,
    error: awardError,
    isLoading: awardLoading,
  }: any = useQuery("award", () => getAwardFullList(), {});


  return (
    <>
      <section className="awards">
        <div className="awards-outer-wrap bg-transparent">
          <Container>
            <div className="section-heading-wrap">
              <div className="left-side">
                <h6 className='sub-heading'>Honors & Milestones</h6>
                <h4 className='main-heading'>Awards & Achievements</h4>
              </div>
            </div>
          </Container>

          {/* Cards section */}
          <div className="awrads-list-outer-wrap">
            <Container>
              <Row className='gy-4'>
                {awardList?.map((award: CardTypes)  => (
                  <Cards props={award} key={award?.id} url_go=""/>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </>
  );
}

export default AwardsList;
