import * as Yup from "yup";
import {
  emailSchema,
  phoneSchema,
} from "../../../Utils/Formik/FormikCommonValidationSchemas";

export const NewUserValidationSchema = Yup.object().shape({
  patient_name: Yup.string().required("Patient name is required"),
  patient_mobile: phoneSchema.required("Patient mobile is required"),
  patient_email: emailSchema.required("Email is required"),
  patient_gender: Yup.string().required("Gender is required"),
  department_id: Yup.string().required("Department is required"),
  message: Yup.string().required("Message is required").trim(),
  date_time: Yup.string().required("Date & Time is required"),
});

