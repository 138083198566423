import React, { useEffect, useState } from "react";
import { Col, Card, CardBody, CardTitle } from "reactstrap";
import { CardTypesDoc } from "../../Type/index";
import { Link } from "react-router-dom";

interface CardProps {
  props: CardTypesDoc;
  // Specify the route path for DoctorProfile with the doctor's ID as a parameter
  to: string;
}

const DocCards: React.FC<CardProps> = ({ props, to }) => {
  const [active_top, setActiveTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [active_top]);

  return (
    <Col xs={12} sm={6} md={6} lg={3} className="">
      <Card className="cardClass specialists-card card">
        {/* Use the 'Link' component with the specified route path */}
        <Link to={`${to}/${props.id}`} onClick={() => setActiveTop(true)}>
          <div className="thumbnail-wrap">
            <img
              src={props?.image_url}
              className="thumbnail-md-cover"
              alt={props?.title}
            />
          </div>

          <CardBody>
            <CardTitle className="name-wrap card-title">
              <h6 className="name">Dr. {props?.user?.name}</h6>
            </CardTitle>
          </CardBody>
        </Link>
      </Card>
    </Col>
  );
};

export default DocCards;
