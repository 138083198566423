import * as Yup from "yup";

export const FeedbackValidationSchema = Yup.object().shape({
  name: Yup.string().required("Full name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
  subject: Yup.string().required("Subject is required"),
});

export const FeedbackInitialValues = {
  name: "",
  email: "",
  subject: "",
  message: "",
  doctor_id:"",
};
