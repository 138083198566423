import React from 'react'
import {DoctorListBanner,DoctorList} from '../../Routes/Imports'

const Doctors:React.FC = () => {
  return (
    <>
    <DoctorListBanner/>
    <DoctorList/>
    </>
  )
}

export default Doctors
