import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import AddFeedback from "../Feedback/AddFeedback";

const Contact: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <section className="contact-us">
        <div className="contact-us-outer-wrap reveal">
          <Container>
            <Row className="gy-4">
              <Col md={12}>
                <div className="section-heading-wrap">
                  <div className="heading">
                    <h6 className="sub-heading">Connecting For Care</h6>
                    <h4 className="main-heading">Contact Us</h4>
                  </div>
                </div>
              </Col>

              <Col md={3} xs={12}>
                <div className="contact-details-wrap">
                  <div className="item">
                    <h6 className="head">Address</h6>
                    <p>
                      Sabitha Eye Care Hospital, St Peter's Jn, Pathanamthitta,
                      Kerala, India
                    </p>
                  </div>
                  <div className="item">
                    <h6 className="head">Phone</h6>
                    <p>Ph: 91-80-22225645</p>
                  </div>
                  <div className="item">
                    <h6 className="head">Email</h6>
                    <p>info@sabitha.com</p>
                  </div>
                  <div className="item">
                  <div className="social-media-icons-wrap">
                        <a href=""><i className='icon icon-facebook-gray xl'></i></a>
                        <a href=""><i className='icon icon-instagram-gray xl'></i></a>
                        <a href=""><i className='icon icon-x xl'></i></a>                        
                        {/* <a href=""><i className='icon icon-linkedin-gray xl'></i></a> */}
                        <a href=""><i className='icon icon-youtube-gray xl'></i></a>
                    </div>
                  </div>
                  <div className="item ">
                    <Button
                      type="button"
                      outline
                      className="sm"
                      onClick={() => setIsOpen(true)}
                    >
                      Write A Feedback
                    </Button>
                  </div>
                </div>
              </Col>

              <Col md={9}>
                <div className="map-wrap">
                  <iframe
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Sabitha%20eye%20care%20hospital,%20%E2%80%A8St%20Peter's%20jn,%20Pathanamthitta,%20%20Kerala,%20India+(Sabitha%20Eye%20Care)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    title="Sabitha Map"
                  >
                    <a href="https://www.gps.ie/sport-gps/">hiking gps</a>
                  </iframe>
                </div>
              </Col>

            </Row>
          </Container>
        </div>
      </section>

      <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title="Feedback">
        <AddFeedback setIsOpen={setIsOpen} doctor_id={'undefined'}/>
      </ModalContainer>
    </>
  );
};

export default Contact;
