import React from "react";
import { useQuery } from "react-query";
import { Container, Row } from "reactstrap";
import { getAwardFullList } from "../../../../Api/WebsiteApi";
import Cards from "../../../../Components/Cards/Card";
import { CardTypes } from "../../../../Type/index";
import { Link } from "react-router-dom";

const Awards: React.FC = () => {
  const { data: awardList }: any = useQuery(
    "award",
    () => getAwardFullList(),
    {}
  );
  const first4Awards = awardList?.slice(0, 4) || [];

  return (
    <>
      {first4Awards?.length !== 0 && (
        <section className="awards">
          <div className="awards-outer-wrap reveal">
            <Container>
              <div className="section-heading-wrap">
                <div className="left-side">
                  <h6 className="sub-heading">Honors & Milestones</h6>
                  <h4 className="main-heading">Awards & Achievements</h4>
                </div>
                {awardList && awardList.length > 4 && (
                  <div className="right-side">
                    <div className="view-link">
                      <Link to="/awards">
                        View All <i className="icon icon-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </Container>

            {/* cards section */}
            <div className="awards-list-outer-wrap">
              <Container>
                <Row className="gy-4">
                  {first4Awards?.map((award: CardTypes) => (
                    <Cards props={award} key={award?.id} url_go="" />
                  ))}
                </Row>
              </Container>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Awards;
