import { Formik } from "formik";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import { api } from "../../../Api/Api";
import {
  getDepartmentFullListSelect,
  getSlots,
} from "../../../Api/WebsiteApi";
import { gender_data } from "../../../Utils/Constants/selection_options";
import NewUserAppointment from "./NewUserAppointment";
import { NewUserValidationSchema } from "./ValidationSchema";
import moment from "moment";

const Appointments = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [slot_value, setSlotValue] = useState();
  // Department List
  const { data: departmentList, isLoading: departmentLoading }: any = useQuery(
    "departmentselect",
    () => getDepartmentFullListSelect(),
    {
      staleTime: 15000,
    }
  );

  // // Doctor List
  // const { data: doctorList, isLoading: doctorLoading }: any = useQuery(
  //   "DoctorSelect",
  //   () => getDoctorsFullListSelect(),
  //   {
  //     staleTime: 15000,
  //   }
  // );

  // Doctor List
  const { data: doctorSlot }: any = useQuery(
    ["DoctorSlots", slot_value],
    () => getSlots(slot_value),
    {
      keepPreviousData: true,
    }
  );

  const initialValues = {
    patient_name: "",
    patient_mobile: "",
    patient_email: "",
    patient_gender: "",
    department_id: "",
    message: "",
    date_time: "",
    patient_id: "",
    doctor_id: "",
  };

  const handleSubmit = (values: any, actions: any) => {
    const date_am = values?.date_time?.split("AM");
    const date_pm = values?.date_time?.split("PM");
    const formattedDate = moment(
      date_am?.[0] || date_pm?.[0],
      "YYYY-MM-DD hh:mm A"
    );
    if (formattedDate.isValid()) {
      api
        .post(
          `/public/appointments`,
          {
            ...values,
            date_time: formattedDate.format("YYYY-MM-DD HH:mm"),
          },
          true
        )
        .then(async function ([success, response]: any) {
          toast.success("Appointment has been created.");
          actions?.resetForm();
          actions?.setSubmitting(false);
        })
        .catch((err) => {
          actions?.setSubmitting(false);
          if (err?.response?.data?.errors) {
            setValidationErrors(err?.response?.data?.errors, actions);
          }
        });
    } else {

    }
  };

  // Sets error message for all fields from response
  const setValidationErrors = (errors: any, actions: any) => {
    Object?.keys(errors)?.map((fieldName) =>
      errors?.[fieldName]?.map((errorMessage: string, index: number) =>
        actions?.setFieldError(fieldName, errorMessage)
      )
    );
  };

  return (
    <>
      <section className="booking">
        <div className="booking-appointment-outer-wrap reveal">
          <Container>
            <div className="booking-wrapper">
              <Row className="align-items-end">
                <Col lg={6} xs={12}>
                  <div className="padding-column">
                    <div className="section-heading-wrap">
                      <div className="heading">
                        <h6 className="sub-heading text-primary">
                          Focus On Your Vision
                        </h6>
                        <h4 className="main-heading text-primary">
                          Schedule Your In-Clinic Consultation Now
                        </h4>
                      </div>
                    </div>
                    <div className="booking-form-wrap">
                      <div className="heading-wrap">
                        <h4 className="">Book An Appointment</h4>
                      </div>

                      <div>
                        {/* main view */}

                        {/* render method */}
                        <Formik
                          initialValues={initialValues}
                          validationSchema={NewUserValidationSchema}
                          onSubmit={(values: any, actions: any) =>
                            handleSubmit(values, actions)
                          }
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            /* and other goodies */
                          }) => (
                            <NewUserAppointment
                              userType={"new-user"}
                              departmentList={departmentList}
                              // doctor={doctorList}
                              // doctorLoading={doctorLoading}
                              genderType={gender_data}
                              values={values}
                              handleSubmit={handleSubmit}
                              isSubmitting={isSubmitting}
                              errors={errors}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              departmentLoading={departmentLoading}
                              setIsOpen={setIsOpen}
                              isOpen={isOpen}
                              setSlotValue={setSlotValue}
                              doctorSlot={doctorSlot}
                              props_time={props}

                            />
                          )}
                        </Formik>
                        {/* end */}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} xs={12}>
                  <div className="booking-img-wrap">
                    <img
                      src="/images/booking-img.webp"
                      alt=""
                      className="banner-image"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Appointments;
