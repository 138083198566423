import React, { useEffect, useLayoutEffect, useState } from "react";
import CustomSpinner from '../../../../Components/Button/CustomSpinner'

//slider
import Slider from "react-slick";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import { api } from "../../../../Api/Api";
import TimeCalender from "../../../../Components/TimeCalender/TimeCalender";
import { useQuery } from "react-query";
import { getDoctorsprofile } from "../../../../Api/WebsiteApi";
import { useParams } from "react-router-dom";
import { string } from "yup";
import { CardTypes } from "../../../../Type";

const DoctorProfile: React.FC = () => {
  //slider
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const [doctorId, setDoctorId] = useState<string | undefined>();
  const { id } = useParams<{ id: string | undefined }>();

  useEffect(() => {
    if (id) {
      setDoctorId(id as string);
    }
  }, [id]);

  const {
    data: doctorList,
    error: doctorError,
    isLoading: doctorLoading,
  }: any = useQuery(["DoctorProfile", doctorId], () => getDoctorsprofile(doctorId), {});

  return (
    <>
      <section className="doc-profile">
        <div className="doc-profile-outer-wrap">
          <Container>
            <Row className="gy-4">
              <Col lg={8} xs={12}>
              {doctorLoading ? (
                    <div ><CustomSpinner /></div>
                ) : (
                <Row className="gy-4">
                  <Col lg={3} md={3} sm={4}>
                    <Card className="doctor-profile-thumbnail-wrap">
                      <img
                        src={doctorList?.image_url}
                        className="thumbnail-sm-cover"
                        alt={doctorList?.user?.name}
                      />
                    </Card>
                  </Col>
                  <Col lg={9} md={9} sm={8}>
                    <div>
                      <div className="name-designation-wrap">
                        <h4>{doctorList?.user?.name}</h4>
                        <p>
                          {doctorList?.designation?.name}<span className="divider">|</span>
                          {doctorList?.experience_years} years of experience
                        </p>
                      </div>
                      <div className="social-media-icons-wrap">
                        <a href="">
                          <i className="icon icon-facebook-2 xl"></i>
                        </a>
                        <a href="">
                          <i className="icon icon-x xl"></i>
                        </a>
                        <a href="">
                          <i className="icon icon-linkedin-2 xl"></i>
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="description">
                      <p className="doctor-info">
                        {doctorList?.description}
                      </p>
                    </div>

                    <div className="awards-about-wrap">
                      <h6>Awards & Achievements</h6>
                      <div className="awards-grid">
                        {doctorList?.awards?.map((award: CardTypes) => (
                          <div key={award?.id} className="thumb">
                            <img
                              src={award?.image_url}
                              alt="Certificate"
                            />
                          </div>))}
                      </div>
                    </div>
                  </Col>
                </Row>
                )}
              </Col>

              <Col lg={4} xs={12}>
                <TimeCalender 
                 doctor={doctorList?.id || ''}/>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default DoctorProfile;
