import React from 'react'
import { 
    Container, 
    Row, 
    Col, 
    List,
    } from 'reactstrap';
const AwardsListBanner:React.FC = () => {
  return (
    <>
          <section className="page-banner">
            <div className="page-banner-wrap">
            <Container>
                <Row>
                    <Col lg={12} xs={12}>
                        <div className="heading-wrap">
                            <h1>Awards & Achievements</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <List className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/">Sabitha</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Awards & Achievements
                                    </li>
                            </List>   
                        </nav>
                    </Col>
                </Row>
            </Container>
            </div>
        </section>
    </>
  )
}

export default AwardsListBanner
