import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from 'reactstrap';
import { CardTypesTestimonials } from '../../../../Type/index';
import { getTestimonialsList } from '../../../../Api/WebsiteApi';
import { useQuery } from 'react-query';

const Testimonial: React.FC = () => {
    const { data: testimonialsList }: any = useQuery("testimonials", () => getTestimonialsList(), {});

    return (
        <>
            {testimonialsList?.length !== 0 &&
                <section className="testimonials">
                    <div className="testimonial-outer-wrap reveal">
                        <Container>
                            <Row className='align-items-center'>
                                <Col lg={3}>
                                    <div className="section-heading-wrap">
                                        <div className="heading">
                                            <h4 className='main-heading text-white'>What People Are Saying</h4>
                                            <h6 className='sub-heading text-white'>Celebrating Stories Of Visionary Transformation</h6>

                                        </div>
                                    </div>
                                </Col>

                                <Col lg={9}>
                                    <div className="testiomonial-cards-wrap">
                                        <Row className='gy-4'>
                                            {testimonialsList?.map((test: CardTypesTestimonials, i: number) => (
                                                <Col sm={12} md={4} lg={4} key={i}>
                                                    <Card className="testimonial-card">
                                                        <div className="thumbnail-wrap">
                                                            <img src={test?.image_url}
                                                                className='thumbnail-sm-cover'
                                                                alt={test?.name}
                                                            />
                                                        </div>
                                                        <CardBody>
                                                            <div className="testimonial-text">
                                                                <p>{test?.description}</p>
                                                            </div>
                                                            <div className="testimonial-by">
                                                                <h6 className='name'>{test?.name}</h6>
                                                                <p className='designation'>{test?.designation}</p>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>}
        </>
    );
}

export default Testimonial;