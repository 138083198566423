import React from 'react'
import {AwardsListBanner,AwardsList} from '../../Routes/Imports'

const AwardsPage:React.FC = () => {
  return (
    <>
    <AwardsListBanner/>
    <AwardsList/>
    </>
  )
}

export default AwardsPage
