import React from 'react'
import { Container, Col } from 'reactstrap';

const TermsAndConditionsList = () => {
  return (
    <>
           <section className="terms-and-conditions">
            <div className="terms-and-conditions-outer-wrap bg-transparent">
                <Container>
                    <div className="section-heading-wrap">
                        <div className="left-side">
                            <h6 className='sub-heading'>Navigating Our</h6>
                            <h4 className='main-heading'>Terms And conditions</h4>
                        </div>
                    </div>
                    <div className="terms-and-conditions-inner-wrap">
                        <Col lg={12} md={12} sm={12}>
                            <h5>23/11/2023</h5>
                            <p>These Terms and Conditions ("Terms") govern the use of the appointment booking service provided by Sabitha eye care hospital via our website. By using this service, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our appointment booking service.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Booking Appointments</h5>
                            <ul>
                                <li> Eligibility: You must be at least 18 years of age or the legal age in your jurisdiction to book an appointment through our website. By booking an appointment, you represent and warrant that you meet these eligibility requirements.</li>
                                <li>Appointment Availability: Our appointment booking service is subject to availability. We do not guarantee the availability of specific time slots or healthcare professionals.</li>
                                <li>Accuracy of Information: You agree to provide accurate and complete information when booking an appointment, including your contact information and any relevant medical information.</li>
                            </ul>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Privacy</h5>
                            <p>Privacy Policy: Your use of our appointment booking service is also governed by our Privacy Policy. By booking an appointment, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Cancellation And Rescheduling</h5>
                            <p>Appointment Changes: If you need to cancel or reschedule your appointment, please do so in a timely manner, as specified on our website or as communicated to you during the booking process.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Payment</h5>
                            <p>Fees: Payment for medical services will be due at the time of the appointment, as specified by our staff during the booking process.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Liability</h5>
                            <p>Limitation of Liability: Sabitha eye care hospital and its affiliates shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our appointment booking service.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Changes To Terms</h5>
                            <p>We may update these Terms from time to time. Any changes will be posted on our website, and the effective date will be indicated at the beginning of the Terms. Your continued use of our appointment booking service after the effective date constitutes your acceptance of the revised Terms.</p>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <h5>Contact Us</h5>
                            <p>If you have any questions, concerns, or requests related to this Privacy Policy or your personal information, please contact us at 91-80-22225645.</p>
                            <p>Thank you for choosing Sabitha eye care hospital for your eye care needs. Your privacy is important to us, and we are committed to safeguarding your information.</p>
                        </Col>
                    </div>
                </Container>

            </div>
        </section>
    </>
  )
}

export default TermsAndConditionsList
