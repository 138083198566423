import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Input, InputGroup } from "reactstrap";

const Footer: React.FC = () => {
  //reveal starts
  useEffect(() => {
    function reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }

    function revealRight() {
      var reveals = document.querySelectorAll(".revealRight");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 100;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }

    function revealRotate() {
      var reveals = document.querySelectorAll(".revealRotate");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 100;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }

    function moveDown() {
      var reveals = document.querySelectorAll(".moveDown");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 50;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }

    window.addEventListener("scroll", reveal);
    window.addEventListener("scroll", revealRight);
    window.addEventListener("scroll", revealRotate);
    window.addEventListener("scroll", moveDown);

    // Clean up event listeners when the component unmounts
    return () => {
      window.removeEventListener("scroll", reveal);
      window.removeEventListener("scroll", revealRight);
      window.removeEventListener("scroll", revealRotate);
      window.removeEventListener("scroll", moveDown);
    };
  }, []);

  //   reveal ends
  return (
    <>
      <footer className="footer reveal">
        <div className="footer-outer-wrap">
          <Container>
            <div className="footer-list-wrap">
              <Row className="gy-4">
                <Col xl={4} xxl={3} xs={12}>
                  <div className="section-heading-wrap">
                    <h6 className="sub-heading">Connecting for care</h6>
                    <h4 className="main-heading">Join our newsletter</h4>
                  </div>

                  <span className="subscribe-wrapper">
                    <InputGroup>
                      <Input placeholder="Enter your email" />
                      <Button color="white" className="md">
                        Subscribe
                      </Button>
                    </InputGroup>
                  </span>
                </Col>

                <Col xl={8} xxl={9} xs={12}>
                  <div className="foot-wrap">
                    <div className="lists">
                      <Link to="/"> Home</Link>
                      <Link to="/about-us" className="header">
                        About Us
                      </Link>
                      {/* <a href="#">Careers</a>
                      <a href="#">News</a>
                      <a href="#">Blogs</a> */}
                    </div>

                    <div className="lists">
                      <Link to="https://www.quickerala.com/pathanamthitta/pathanamthitta/sabitha-eye-care-hospital/60663">
                        Help Center
                      </Link>
                      <Link to="/contact-us">Contact Us</Link>
                      <Link to="/services"> Services</Link>
                    </div>

                    <div className="lists">
                      <Link to="https://www.facebook.com/p/Sabitha-Eye-Care-Hospital-100054302419068/">
                        Company
                      </Link>
                      <Link to="/book/appoinment">Book an appointment</Link>
                      {/* <a href="#">Testimonials</a> */}
                      <Link to="/doctors">Team</Link>
                    </div>

                    <div className="lists">
                      <a href="#" className="header">
                        Legal
                      </a>
                      <Link to="/privacy-policy">Privacy policy</Link>
                      <Link to="/terms-and-conditions">Terms and Condition</Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="copyright-text-wrap">
            <Container>
              <Row>
                <div className="copyright-inner-wrap">
                  <small className="company-name">
                    <i className="icon icon-copyright"></i> 2023 Sabitha Eye
                    Care Hospital.
                  </small>
                  <small>
                    Developed and Maintained by{" "}
                    <Link to="https://orisys.in/">OrisysIndia</Link>
                  </small>
                </div>
              </Row>
            </Container>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
