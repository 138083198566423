import React from 'react';
import TermsAndConditionsBanner from './Containers/TermsAndConditionsBanner';
import TermsAndConditionsList from './Containers/TermsAndConditionsList';

const TermsAndConditions:React.FC = () => {
	return (
		<>
			<TermsAndConditionsBanner />
			<TermsAndConditionsList />
		</>
	);
};

export default TermsAndConditions;