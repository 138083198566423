import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

const TimeSlotConfirmModal = ({
  setIsOpen,
  isOpen,
  handleDate,
  selectedDate,
  selectedTime,
  filteredTimeSlots,
}: any) => {
  return (
    <div className="form-wrap">
      <Row className="gy-4">
        <Col lg={12}>
          <div className="modal-warning-text-wrap">
            <div className="warning-img-wrap">
              <img src="/images/delete-warning.svg" alt="warning-icon" />
            </div>
            <h5 className="modal-warning-title">Are you sure ?</h5>
            <small className="sub-text">
              Your Booking Date & Time{" "}
              <span className="date-time-block">{moment(selectedDate).format("YYYY-MM-DD")} &nbsp;
              {filteredTimeSlots?.length === 0
                ? moment(selectedTime).format("hh:mm A")
                : selectedTime}
                </span>         
            </small>
          </div>
        </Col>

        <Col lg={12}>
          <div className="submit-btn-wrap centered">
            <Button
              color={"secondary"}
              outline
              className="sm"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              color={"success"}
              className="sm"
              onClick={() => handleDate(selectedDate, selectedTime)}
            >
              Confirm
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TimeSlotConfirmModal;
