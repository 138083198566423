/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
} from "reactstrap";
import { useQuery } from "react-query";
import {
  getBannerFullList,
  getServicesFullList,
} from "../../../../Api/WebsiteApi";
import { CardTypes, CardTypesBanner } from "../../../../Type";
import { Link } from "react-router-dom";
import { capitalizeFirstLetterOfEachWord } from "../../../../Utils/helpers/helpers";
import Slider from "react-slick";

interface BannerProps {
  scrollToAppointments: () => void;
}

const Banner: React.FC<BannerProps> = ({ scrollToAppointments }) => {
  const { data: bannerList, isLoading: bannerLoading }: any = useQuery(
    "banner",
    () => getBannerFullList(),
    { keepPreviousData: true }
  );
  const { data: serviceList }: any = useQuery(
    "service",
    () => getServicesFullList(),
    {}
  );
  const first5serviceList = serviceList?.slice(0, 5) || [];
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover:false,
    cssEase: 'ease-in-out',
    afterChange: (index:any) => setCurrentBannerIndex(index),
  };

  return (
    <section className="home-banner">
      <div className="home-banner-outer-wrap">
        <div className="home-banner-inner-wrap">
          <Container>
            <Slider {...settings}>
              {!bannerLoading &&
                bannerList?.map((banner: CardTypesBanner, index: number) => (
                  <div key={index}>
                    <Row className="align-items-center gy-4">
                      <Col
                        lg={6}
                        md={6}
                        xs={12}
                        style={{
                          display:
                            index === currentBannerIndex ? 'block' : 'none',
                        }}
                      >
                        <div className="banner-text">
                          <h1>
                            {capitalizeFirstLetterOfEachWord(banner?.heading)}{' '}
                            <br />
                            <span>
                              {capitalizeFirstLetterOfEachWord(
                                banner?.sub_heading
                              )}
                            </span>
                          </h1>
                          <p>
                            {capitalizeFirstLetterOfEachWord(banner?.content)}
                          </p>
                          <div className="appointment-btn">
                            <Button
                              outline
                              color="white"
                              className="sm"
                              onClick={scrollToAppointments}
                            >
                              Book An Appointment
                            </Button>
                          </div>
                        </div>
                      </Col>
                      {banner?.image_url && (
                        <Col
                          lg={6}
                          md={6}
                          style={{
                            display:
                              index === currentBannerIndex ? 'block' : 'none',
                          }}
                        >
                          <div className="banner-image">
                            <img
                              src={banner?.image_url}
                              alt="banner"
                              className="banner-image w-100"
                            />
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                ))}
            </Slider>
          </Container>
      </div>
      {/* card section */}
      {first5serviceList && first5serviceList?.length > 0 && (
        <div className="services-outer-wrap">
          <Container>
            <div className="services-inner-wrap">
              {first5serviceList?.map((service: any, index: number) => (
                <Card
                  className="service-card-variant"

                >
                  <Link to="/services">
                    <CardHeader style={{ backgroundColor: service?.color }}>
                      <div className="icon-wrap">
                        <img
                          className="thumbnail-xs-contain"
                          src={service?.image_url}
                          alt="image"
                        />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <CardTitle tag="p" title={service?.title}>
                        {service?.title?.substring(0, 10)}
                        {service?.title?.length >= 10 ? "..." : null}
                      </CardTitle>
                    </CardBody>
                  </Link>
                </Card>
              ))}
              {serviceList && serviceList?.length > 5 && (
                <Card className="view-all-card">
                  <CardBody>
                    <Link to="/services">
                      View All <i className="icon icon-arrow-right"></i>
                    </Link>
                  </CardBody>
                </Card>
              )}
            </div>
          </Container>
        </div>
      )}
    </div>

      {/* Sticky-fixed-btn */ }
  <div className="sticky-btn-right">
    <Button color="secondary" className="sm" onClick={scrollToAppointments}>
      Book An Appointment
    </Button>
  </div>
    </section >
  );
};

export default Banner;
