import * as Yup from 'yup';
import { validateEmail, validatePhone } from './FormikCommonMethods';

// Common validation schemas
// phone number 
const phoneSchema = Yup.string().matches(/^\d{10}$/, 'Invalid phone number');

// email 
const emailSchema = Yup.string().email('Invalid email format');

// password 
const PasswordSchema = Yup.string().min(8, "Password must be at least 8 characters").max(40, "Password must not exceed 40 characters")

// phone or email field 
const PhoneOrEmailSchema = Yup.string().test('email_or_phone', 'Email / Phone is invalid', (value: any) => validateEmail(value) || validatePhone(parseInt(value ?? '0')))

export { phoneSchema, emailSchema, PhoneOrEmailSchema, PasswordSchema }