import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  List,
  Button,
} from "reactstrap";
import { getDoctorsprofile } from "../../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import { CardTypesDocProfile } from "../../../../Type";
import { useParams } from "react-router-dom";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import AddFeedback from "../../Home/Feedback/AddFeedback";
import CustomSpinner from "../../../../Components/Button/CustomSpinner";

const DoctorInfoCard: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const {
    data: doctorList,
    error: doctorError,
    isLoading: doctorLoading,
    refetch: doctorListRefetch,
  }: any = useQuery("DoctorProfile", () => getDoctorsprofile(id), {});

  const formatDate = (timestamp: string | number | Date) => {
    const dateObject = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return dateObject.toLocaleDateString(undefined, options);
  };

  return (
    <>
      <section className="doctor-details-more">
        <div className="doctor-detail-cards-wrap">
          <Container>
            <Row className="gy-4">
              <Col lg={4} md={4} className="colored-card-col">
                <Card className="colored-card">
                  <CardBody className="colored-card-wrap">
                    <CardTitle tag="h4">Education</CardTitle>
                    {doctorLoading ? (
                      <div>
                        <CustomSpinner />
                      </div>
                    ) : (
                      <CardText>
                        {doctorList?.educations?.map(
                          (edu: CardTypesDocProfile) => (
                            <List className="doc-list" key={edu?.id}>
                              <li>
                                <span>
                                  <h6>{edu?.qualification}</h6>
                                  <p>{edu?.institution}</p>
                                </span>
                              </li>
                            </List>
                          )
                        )}
                      </CardText>
                    )}
                  </CardBody>
                </Card>
              </Col>

              <Col lg={4} md={4} className="colored-card-col">
                <Card className="colored-card">
                  <CardBody className="colored-card-wrap">
                    <CardTitle tag="h4">Skill</CardTitle>
                    {doctorLoading ? (
                      <div>
                        <CustomSpinner />
                      </div>
                    ) : (
                      <CardText>
                        {doctorList?.skills?.map(
                          (skill: CardTypesDocProfile) => (
                            <List className="doc-list" key={skill?.id}>
                              <li>{skill?.skill}</li>
                            </List>
                          )
                        )}
                      </CardText>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4} md={4} className="colored-card-col">
                <Card className="colored-card">
                  <CardBody className="colored-card-wrap">
                    <CardTitle tag="h4">Experience</CardTitle>
                    <CardText>
                      {doctorList?.experiences?.map(
                        (exp: CardTypesDocProfile) => (
                          <List className="doc-list" key={exp?.id}>
                            <li>
                              <span>
                                <h6>{exp?.institution}</h6>
                                <p>
                                  {exp?.fromdate} - {exp?.todate}
                                </p>
                              </span>
                            </li>
                          </List>
                        )
                      )}
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/* feedback */}

      <section className="feedback-details">
        <div className="feedback-details-cards-wrap">
          <Container>
            <div className="section-heading-wrap">
              <div className="left-side-wrap">
                <h4 className="main-heading">Feedback</h4>
              </div>
              <div className="right-side-wrap">
                <Button
                  className="sm"
                  color="secondary"
                  onClick={() => setIsOpen(true)}
                  outline
                >
                  Write A Feedback
                </Button>
              </div>
            </div>
            <Row className="gy-4">
              <div className="feedback-outer-wrap mt-2">
                <Row className="gy-4">
                  {doctorList?.feedbacks?.map(
                    (feedback: CardTypesDocProfile) => (
                      <Col md={4} key={feedback?.id}>
                        <Card className="feedback-card publish">
                          <CardBody>
                            <div className="gy-4 align-items-center">
                              <div className="feedback-details-wrap">
                                <div className="feedback-head-wrap">
                                  <h4 className="name">{feedback?.name}</h4>
                                  <h6 className="date">
                                    {formatDate(new Date(feedback?.created_at))}
                                  </h6>
                                  <h5 className="sub-head">
                                    {feedback?.subject}
                                  </h5>
                                </div>
                                <div className="feedback-description">
                                  <p>" {feedback?.message} "</p>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    )
                  )}
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      </section>
      <ModalContainer isOpen={isOpen} setIsopen={setIsOpen} title="Feedback">
        <AddFeedback
          setIsOpen={setIsOpen}
          doctor_id={id as any}
          Refetch={doctorListRefetch}
        />
      </ModalContainer>
    </>
  );
};

export default DoctorInfoCard;
