import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { getDoctorsFullList } from '../../../../Api/WebsiteApi';
import DocCards from '../../../../Components/Cards/DocCard';
import { CardTypesDoc } from '../../../../Type/index';


const Team: React.FC = () => {

    const { data: doctorList }: any = useQuery("Doctor", () => getDoctorsFullList(), { keepPreviousData: true });
    const first4Doctor = doctorList?.slice(0, 4) || [];

    return (
        <>
            {first4Doctor.length !== 0 && (
                <section className="team">
                    <div className="team-home-outer-wrap reveal">
                        <Container>
                            <div className="section-heading-wrap">
                                <div className='left-side-wrap'>
                                    <h6 className='sub-heading'>Caring Hands And Expertise</h6>
                                    <h4 className='main-heading'>Meet Our Specialists</h4>
                                </div>
                                {doctorList?.length > 4 &&
                                    <div className='right-side-wrap'>
                                        <div className="view-link">
                                            <Link to="/doctors" >View All <i className='icon icon-arrow-right'></i></Link>
                                        </div>
                                    </div>}
                            </div>
                        </Container>
                        {/* cards section */}
                        <div className="team-list-outer-wrap">
                            <Container>
                                <Row className='gy-4'>
                                    {first4Doctor?.map((doctor: CardTypesDoc) => (
                                        <DocCards props={doctor} key={doctor?.id} to={`/doctor/profile`} />
                                    ))}
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default Team;
