import React, { useState } from "react";
import { Col, Card, CardBody, CardTitle } from "reactstrap";
import { CardTypes } from "../../Type/index";
import { Link } from "react-router-dom";

interface CardProps {
  props: CardTypes;
  url_go: string;
}



const Cards: React.FC<CardProps> = ({ props, url_go }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {

    setShowTooltip(true);
  };

  const handleMouseLeave = () => {

    setShowTooltip(false);
  };

  return (
    <Col xs={12} sm={6} md={6} lg={3} reveal className='awardsCardClass'>
      <Card className='awards-card awardsCardClass'>
        <Link to={url_go}>
          <div className='thumbnail-wrap'>
            <img
              src={props?.image_url}
              className='thumbnail-md-cover'
              alt={props?.title}
            />
          </div>

          <CardBody>
            <CardTitle className="awards-title-wrap card-title">
              <h6 className="black">{props?.title}</h6>
            </CardTitle>
          </CardBody>
        </Link>

        {showTooltip && (
          <div >{props?.description}</div>
        )}
      </Card>
    </Col>
  );
};

export default Cards;
